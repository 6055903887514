import Utils from "../Utils";
import axios from "axios";

export default (function() {
  function isRedirectPage() {
    return document.body.classList.contains('coupons') && document.body.classList.contains('clickout');
  }

  function triggerTrackingUserEvent() {
    const event = new CustomEvent('tracking_user_set');
    document.dispatchEvent(event);
  }

  function setUserTracking() {
    if (isRedirectPage()) {
      return;
    }

    const urlWithoutQueryString = window.location.href.split('?')[0];
    let allVars = {};

    if (window.location.href.indexOf('?') !== -1 && urlWithoutQueryString.indexOf('search') === -1) {
      allVars = Utils.getUrlVars();
    }

    let referrer = document.referrer;

    if (referrer === '' || referrer === "undefined") {
      referrer = 'unknown';
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    allVars['referrer'] = referrer;

    axios
      .post(window.rootDir + '/tracking/set', {query_string_params: allVars})
      .then(function(response) {
        const data = response.data;
        window.pc_tracking_user_id = data.tracking_user_id;
        triggerTrackingUserEvent();
      })
      .catch(err => {
        console.error(err.response);
      });
  }

  return {
    run: setUserTracking
  };
})();
