import log from "../../Utils/log";
import mediator from "libs/mediator";

export default (function() {
  let updateEvent;

  function run() {
    log("LazyImagesUpdate:run");

    updateEvent = new Event("lazy_images:update");
    mediator.on("lazy_images:update", update)
  }

  function update() {
    document.dispatchEvent(updateEvent);
  }

  return {
    run: run
  }
})();
