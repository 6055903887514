import Utils from "libs/Utils";
import log from "../Utils/log";

export default (function() {
  function countdown() {
    let el = document.querySelector("[data-module='hero_countdown']");

    log("HeroCountdown:init")
    if (el && el.dataset.time) {
      el.classList.remove('hidden');
      Utils.countdown(el, {date: el.dataset.time, format: "on"});
    }
  }

  return {
    run: countdown
  };
})();
