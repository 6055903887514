import mediator from "libs/mediator";

export default (function() {
  function init() {
    const MODIFIER = '--full';

    let buttons = document.querySelectorAll("[data-see-details]");
    let newsletter = document.querySelector(".w-newsletter--for-coupons, .full-newsletter__form, .widget-newsletter, .coupon--newsletter");

    buttons.forEach((button) => {
      if (!button.hasAttribute("data-has-event")) {

        button.dataset.hasEvent = "true";

        let moreText = button.dataset.moreText;
        let lessText = button.dataset.lessText;

        button.addEventListener('click', (ev) => {
          let description = button.closest('.coupon, .modal-clickout, .modal-gift__body, .authors-sector__info, .authors-page__item').querySelector('[data-description]');
          let modifier = description.classList[0] + MODIFIER;

          ev.preventDefault();
          ev.stopPropagation();

          if (description) {
            if (description.classList.contains(modifier)) {
              description.classList.remove(modifier);

              button.classList.remove("coupon__see-details--opened");
              button.innerText = moreText;
            } else {
              description.classList.add(modifier);

              button.classList.add("coupon__see-details--opened");
              button.innerText = lessText;
            }
          }
        });
      }
    });

    if (newsletter) {
      const toggleNewsletter = newsletter.querySelector('[data-see-newsletter]');
      const toggleNewsletterClass = 'coupon__more-details';
      const modifierForToggle = 'opened';

      if (toggleNewsletter && !toggleNewsletter.hasAttribute("data-has-event")) {
        toggleNewsletter.dataset.hasEvent = "true";

        let moreText = toggleNewsletter.dataset.moreText;
        let lessText = toggleNewsletter.dataset.lessText;

        toggleNewsletter.addEventListener('click', function() {
          openFullText();
          toggleNewsletter.classList.toggle(modifierForToggle);

          if(toggleNewsletter.classList.contains(modifierForToggle)) {
            toggleNewsletter.classList.add(`${toggleNewsletterClass}--opened`);
            toggleNewsletter.innerText = lessText;
          } else {
            toggleNewsletter.classList.remove(`${toggleNewsletterClass}--opened`);
            toggleNewsletter.innerText = moreText;
          }
        });
      }
    }

    function openFullText() {
      let fullText = newsletter.querySelector('.coupon__text--full, .w-newsletter__text--full, .text-full');
      let smallText = newsletter.querySelector('.coupon__text--truncated, .w-newsletter__text--truncated, .text-truncated');

      if(!fullText || !smallText) {
        return;
      }

      if (fullText.classList.contains('hidden')) {
        fullText.classList.remove('hidden');
        smallText.classList.add('hidden');
      } else {
        fullText.classList.add('hidden');
        smallText.classList.remove('hidden');
      }
    }
  }

  function run() {
    mediator.on("showmore", init);
  }

  return {
    run: run
  };
})();
