import log from "../Utils/log";

export default (function() {
  let slider, sliderList, slideWidth, sliderDotNavigation;

  function init() {
    slider = document.querySelector('[data-widget="carousel"]');
    if (!slider) {
      return;
    }

    log('Carousel:init');

    sliderList = slider.querySelector('[data-list]');
    slideWidth = sliderList.querySelector('[data-item]').clientWidth;
    sliderDotNavigation = slider.querySelector('[data-nav-dots]');

    initDots();
    handlerDotsClick();
    slide(sliderList, sliderDotNavigation);


    sliderList.addEventListener("scroll", function() {
      slide(this, sliderDotNavigation);
    });

    slider.querySelector('.next[data-nav-arrow-item]').addEventListener('click', scrollToNextSlide);
    slider.querySelector('.prev[data-nav-arrow-item]').addEventListener('click', scrollToPrevSlide);
  }

  function initDots() {
    let slideCount = sliderList.childElementCount;
    let dotExample = sliderDotNavigation.firstElementChild.cloneNode(true);

    for (let i = 2; i <= slideCount; i++) {
      dotExample.innerHTML = i;
      dotExample.dataset.slideId = 'slide' + i;
      let dotNew = dotExample.cloneNode(true);
      sliderDotNavigation.appendChild(dotNew);
    }
  }

  function handlerDotsClick() {
    sliderDotNavigation.addEventListener("click", function(event) {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.tagName === "LI") {
        let dot = event.target.closest("li");

        let matchElement = sliderList.querySelector(
          "li[id='" + dot.dataset.slideId + "']"
        );
        sliderList.scrollTo({left: matchElement.offsetLeft, behavior: "smooth"});
      }
    });
  }

  function slide(element, dotsContainer) {
    let scrollLeft = element.scrollLeft;
    let slides = element.children;
    let activeSlide;

    for (let i = 0, length = slides.length; i < length; i++) {
      if (Math.abs(slides[i].offsetLeft - scrollLeft) < 50) {
        activeSlide = slides[i];
      }
    }

    if (activeSlide) {
      let navigateDots = dotsContainer.querySelectorAll('[data-nav-dots-item]');

      for (let i = 0; i < navigateDots.length; i++) {
        navigateDots[i].classList.remove("active");
      }

      let activeDot = dotsContainer.querySelector(`[data-nav-dots-item][data-slide-id='${activeSlide.id}']`);
      activeDot.classList.add("active");
    }
  }

  function scrollToNextSlide(event) {
    event.preventDefault();
    sliderList.scrollBy({left: slideWidth, behavior: "smooth"});
  }

  function scrollToPrevSlide(event) {
    event.preventDefault();
    sliderList.scrollBy({left: -slideWidth, behavior: "smooth"});
  }

  return {
    run: init
  };
})();
