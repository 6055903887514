import TrackingEvent from "./TrackingEvent.interface";
import {getPageType} from "./helpers";

export default function collectData(element: HTMLElement) {
  const isTrackingElement = element.closest('[data-tracking-element]');
  const closest = element.closest('[data-index]') as HTMLElement;
  const parentArea = element.closest('[data-area]') as HTMLElement;
  const parentElement = element.closest('[data-element]') as HTMLElement;
  const pageType = getPageType();
  const couponId = element.dataset.couponId || 'null';
  const couponTitle = element.dataset.couponTitle || 'null';
  const couponArea = parentArea?.dataset.area || "MB";
  const shopId = element.dataset.shopId || window.eventLayer[0]?.shop_id || element.closest('a')?.dataset.shopId ||"null";
  const shopName = element.dataset.shopName || element.closest('a')?.dataset.shopName || document.body.dataset.trackingData;
  const shopIdLanding = element.dataset.landingPageShopId || "null";
  const couponPosition = closest?.dataset?.index || 'null';
  const couponElement = parentElement?.dataset.element;
  const trackingData = document.body.dataset.trackingData;
  const trackingLabel = element.dataset.trackingLabel;
  const bannerId = element.dataset.id;
  const isBanner = !!element.closest("[data-banner]");
  const isEarthMark = !!element.closest("[data-earthmark]");
  const isNewsletter = !!element.closest("[data-newsletter]");
  const isComingSoonMOD = !!element.closest("[data-coming-soon]");
  const bannerTheme = isBanner ? element.dataset.bannerEventLabel : 'null';
  const anchor = element.closest('a')?.dataset.anchor || element.closest('a')?.dataset.anchor;
  const linkType = element.closest('a')?.dataset.linkType;
  const clickoutElements = [
    isBanner,
    isNewsletter,
    isComingSoonMOD
  ];

  function getClickoutType(): TrackingEvent["type"] {
    switch (true) {
      case Boolean(isTrackingElement):
        return "non-clickout";
      case clickoutElements.includes(true):
        return "click";
      case Boolean(isEarthMark):
        return "earthMark";
      default:
        return "clickout";
    }
  }

  return {
    type: getClickoutType(),
    page_type: pageType,
    coupon_id: couponId,
    coupon_title: couponTitle,
    coupon_area: couponArea,
    shop_id: shopId,
    shop_name: shopName,
    shop_id_landing: shopIdLanding,
    coupon_position: couponPosition,
    coupon_element: couponElement,
    tracking_data: trackingData,
    tracking_label: trackingLabel,
    is_earth_mark: isEarthMark,
    anchor: anchor,
    link_type: linkType,
    is_banner: isBanner,
    banner_id: bannerId,
    banner_theme: bannerTheme
  } as TrackingEvent;
}
