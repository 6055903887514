import mediator from "libs/mediator";
import Utils from "../../Utils/";

export default (function() {
  let stickyBar, footer, cookieBar;
  const SCROLL_TO_TOP_PADDING = 30;
  cookieBar = document.querySelector('.cookies-eu');
  stickyBar = document.querySelector('.sticky-bar');

  footer = document.querySelector('[data-footer]');

  if (!footer) {
    footer = document.getElementById("footer");
  }

  function setFooterPadding() {
    if (footer) {
      let paddingBottom = (isVisible(stickyBar) && Utils.outerHeight(stickyBar)) + (isVisible(cookieBar) && Utils.outerHeight(cookieBar));
      footer.style.paddingBottom = paddingBottom + "px";
      document.querySelector('.scroll-to-top').style.bottom = paddingBottom + SCROLL_TO_TOP_PADDING + "px";
    }
  }

  function isVisible(el) {
    if (!el) {
      return false;
    }

    return Utils.isInViewPort(el);
  }

  function run() {
    Utils.log("BannersUtil init");

    document.body.addEventListener("banner:show", function() {
      setTimeout(function() {
        setFooterPadding();
      }, 500);
    });

    document.body.addEventListener("banner:hide", function() {
      setTimeout(function() {
        setFooterPadding();
      }, 500);
    });

    mediator.on("banner:show", function() {
      setTimeout(function() {
        setFooterPadding();
      }, 500);
    });

    mediator.on("banner:hide", function() {
      setTimeout(function() {
        setFooterPadding();
      }, 500);
    });
  }

  return {
    run: run
  };
})();
