import axios from "axios";
import mediator from "libs/mediator";
import log from "../../Utils/log";

export default (function() {
  let form;

  mediator.on('newsletter-modal:show', init);

  function init() {
    log("NewsLetters init");

    form = document.querySelector("[data-module='newsletter']");

    if (form) {
      form.addEventListener("submit", function(ev) {
        ev.preventDefault();
        subscribe(form);
      });
    }
  }

  function showModal(data) {
    let Modal = document.querySelector("#my-modal .modal__content");
    Modal.innerHTML = data;
    mediator.trigger("modal:show");
  }

  function subscribe(form) {
    let button = form.querySelector("[data-submit]");
    let buttonText = button.innerText;

    button.innerHTML = "<div class='loader'></div>";
    button.classList.add("loading");

    let data = new FormData(form);

    let shopId = form.getAttribute("data-shop");
    if (shopId) {
      data.append("shop_id", shopId);
    }

    let campaignId = form.getAttribute("data-campaign");
    if (campaignId) {
      data.append("campaign_id", campaignId);
    }

    axios
      .post(form.action, data)
      .then(response => {
        showModal(response.data);
        mediator.trigger("newsletter-modal:track");
      })
      .then(() => {
        button.innerText = buttonText;
        button.classList.remove("loading");
      });
  }

  return {
    run: init
  };
})();
