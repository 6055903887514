const htmlUnescapes = {
  '%20': ' ',
  '%26': '&'
}

const reEscapedHtml = /%(20|26)/g
const reHasEscapedHtml = RegExp(reEscapedHtml.source)

function unescape(string) {
  return (string && reHasEscapedHtml.test(string))
    ? string.replace(reEscapedHtml, (entity) => (htmlUnescapes[entity] || "'") )
    : (string || '')
}

export default unescape
