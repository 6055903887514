import gaTracker from "./gaTracking";
import pianoTracker from "./pianoTracking";
import ga4Tracker from "./ga4Tracking";
import TrackingEvent from "../TrackingEvent.interface";

export default function sendAll(eventData: TrackingEvent) {
  [gaTracker, pianoTracker, ga4Tracker].forEach(tracker => {
    tracker.sendProviderEvent(eventData);
  });
}
