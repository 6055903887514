import axios from "axios";
import log from "../../Utils/log";
import mediator from "libs/mediator";

export default (function() {

  function ShowSubscribeModal() {
    const giftModule = document.querySelector("[data-module='gift-pop-up']");

    if (giftModule) {
      let giftForm = giftModule.querySelector("[data-form]");

      if (giftForm) {
        giftForm.addEventListener("submit", function(ev) {
          ev.preventDefault();
          getGiftBox(giftForm, giftModule);
        });
      }

      toggleGiftTerms(giftModule);
    }
  }

  function getGiftBox(form, module) {
    let data = new FormData(form);

    axios
      .post(form.action, data)
      .then(() => {
        switchToEmailCompletedBox(module, form);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function switchToEmailCompletedBox(module, form) {
    const img = module.querySelector('[data-img-completed]');
    const text = module.querySelector('[data-text-completed]');

    img.classList.remove('modal-gift__completed-img--hidden');
    text.classList.remove('modal-gift__completed-text--hidden');
    form.classList.add('modal-gift__form--hidden');
  }

  function toggleGiftTerms(module) {
    let toggle = module.querySelector("[data-toggle]");
    let terms = module.querySelector("[data-terms-text]");

    if(!toggle && !terms) {
      return false;
    }

    toggle.addEventListener('click', (ev) => {
      ev.preventDefault();

      if (terms.classList.contains('modal-gift__terms--small')) {
        terms.classList.remove('modal-gift__terms--small');
        toggle.innerHTML = '<i class="roberto-chevron-up"></i> <span>' + toggle.getAttribute('data-less-text') + '</span>';
      } else {
        terms.classList.add('modal-gift__terms--small');
        toggle.innerHTML = '<i class="roberto-chevron-down"></i> <span>' + toggle.getAttribute('data-more-text') + '</span>';
      }
    });
  }

  function init() {
    log("ModalGift init");
    mediator.on("modal:show", ShowSubscribeModal);
  }

  return {
    run: init
  };
})();
