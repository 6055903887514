import log from "../Utils/log";

export default (function() {
  function run() {
    let widgets = document.querySelectorAll('[data-widget="earthmarkShops"]');

    widgets.forEach((widget) => {
      if (!widget) {
        return;
      }

      log('earthmarkShops:init');
      let container, containerWidth, shops, shopsCount, isMobile, shopWidth, arrowContainerPrev, arrowContainerNext,
        arrowNavigationPrev, arrowNavigationNext, elementScrollCountMobile, elementScrollCountDesktop, elementGap,
        scrollStepMobile, scrollStepDesktop, scrollIndicator, scrollTracker, knowMoreBlock, knowMoreToggle,
        maxShowingElements, notFitWidth;
      let clickCount = 0;

      knowMoreToggle = widget.querySelector('[data-know-more-toggle]');
      shops = widget.querySelectorAll('[data-shop-item]');
      knowMoreBlock = widget.querySelector('[data-know-more-block]');
      knowMoreToggle.addEventListener('click', toogleKnowMoreBlock);

      if (shops.length == 0) {
        return;
      }
      container = widget.querySelector('[data-container]');
      containerWidth = container.offsetWidth;
      shopsCount = shops.length;
      shopWidth = shops.item(0).offsetWidth;
      isMobile = window.matchMedia("(max-width: 991px)").matches;
      elementScrollCountMobile = 2;
      elementScrollCountDesktop = 3;
      arrowContainerPrev = widget.querySelector('[data-container-arrow-prev]');
      arrowContainerNext = widget.querySelector('[data-container-arrow-next]');
      arrowNavigationPrev = widget.querySelector('[data-navigation-arrow-prev]');
      arrowNavigationNext = widget.querySelector('[data-navigation-arrow-next]');
      scrollIndicator = widget.querySelector('[data-navigation-indicator]');
      scrollTracker = widget.querySelector('[data-navigation-tracker]');

      if (shopsCount >= 3) {
        elementGap = (shops.item(isMobile ? 2 : 1).getBoundingClientRect().left - container.getBoundingClientRect().left - shopWidth);
        // if elementGap is calculated incorrectly, please set it manually

        if (isMobile) {
          maxShowingElements = parseInt(((containerWidth + elementGap) / (shopWidth + elementGap)) + '', 10) * 2;
        } else {
          maxShowingElements = parseInt((containerWidth / (shopWidth + elementGap)) + '', 10);
        }

        notFitWidth = containerWidth - (maxShowingElements * (shopWidth + elementGap));
        scrollStepMobile = (shopWidth + elementGap) * elementScrollCountMobile; // number - how many items to scroll
        scrollStepDesktop = (shopWidth + elementGap) * elementScrollCountDesktop; // number - how many items to scroll
        showNextArrows(10, 0);
      } else {
        maxShowingElements = shopsCount;
      }

      function initControl() {
        if (shops.length <= maxShowingElements) {
          inactiveAllArrows();
        } else {
          showWidthTracker();
        }

        arrowContainerPrev.addEventListener('click', () => {
          scrollToPrevSlide();
          scrollingTracker('prev');
        });
        arrowNavigationPrev.addEventListener('click', () => {
          scrollToPrevSlide();
          scrollingTracker('prev');
        });
        arrowContainerNext.addEventListener('click', () => {
          scrollToNextSlide();
          scrollingTracker('next');
        });
        arrowNavigationNext.addEventListener('click', () => {
          scrollToNextSlide();
          scrollingTracker('next');
        });
      }

      function scrollToPrevSlide() {
        let maxScrollRight = 0, valueScroll;

        if (isMobile) {
          valueScroll = container.scrollLeft - scrollStepMobile;
        } else {
          valueScroll = container.scrollLeft - scrollStepDesktop;
        }

        container.scrollLeft = valueScroll;
        showNextArrows(maxScrollRight, valueScroll);
      }

      function scrollToNextSlide() {
        let maxScrollLeft, valueScroll;

        maxScrollLeft = container.scrollWidth - (((shopWidth + elementGap) * maxShowingElements) + elementGap + notFitWidth);
        if (isMobile) {
          valueScroll = container.scrollLeft + scrollStepMobile;
        } else {
          valueScroll = container.scrollLeft + scrollStepDesktop;
        }

        container.scrollLeft = valueScroll;
        showPrevArrows(maxScrollLeft, valueScroll);
      }

      function showPrevArrows(scrollLeft, value) {
        arrowContainerPrev.classList.remove('hidden');
        arrowNavigationPrev.classList.add('active');

        if (scrollLeft == value || scrollLeft <= value) {
          arrowContainerNext.classList.add('hidden');
          arrowNavigationNext.classList.remove('active');
        } else {
          arrowContainerNext.classList.remove('hidden');
        }
      }

      function showNextArrows(scrollRight, value) {
        arrowContainerNext.classList.remove('hidden');
        arrowNavigationNext.classList.add('active');

        if (value == scrollRight || value <= scrollRight) {
          arrowContainerPrev.classList.add('hidden');
          arrowNavigationPrev.classList.remove('active');
        } else {
          arrowContainerPrev.classList.remove('hidden');
        }
      }

      function showWidthTracker() {
        let indicatorWidth, widthOfTracker, numberOfScrolls;

        indicatorWidth = scrollIndicator.offsetWidth;

        if (shopsCount <= maxShowingElements) {
          numberOfScrolls = 1;
        } else {
          numberOfScrolls = Math.ceil((shopsCount - maxShowingElements) / elementScrollCountDesktop) + 1;
        }

        widthOfTracker = indicatorWidth / numberOfScrolls;
        scrollTracker.style.width = widthOfTracker + "px";
      }

      function inactiveAllArrows() {
        arrowContainerNext.classList.add('hidden');
        arrowNavigationNext.classList.remove('active');
      }

      function scrollingTracker(param) {
        if (isMobile) {
          return;
        }

        let width = parseInt(scrollTracker.style.width, 10);

        if (param === 'prev') {
          clickCount--;
        } else {
          clickCount++;
        }

        scrollTracker.style.left = (width * clickCount + "px");
      }

      function toogleKnowMoreBlock() {
        knowMoreBlock.classList.toggle('opened');
      }

      initControl();
    });
  }

  return {
    run: run
  };

})();
