import {shouldBeSkipped} from "libs/Tracking/helpers";

export default (function() {
  const clickoutSelector = "data-coupon-url";
  const embedEl = document.querySelector("[data-area='MB']");

  function init() {
    if (embedEl) {
      embedEl.addEventListener("click", (ev: Event) => {
        defineAndTrackClick(ev);
      });
    }
  }

  function defineAndTrackClick(ev: Event) {
    const Target = ev.target as HTMLElement;
    const parentClickout = Target.closest(`[${clickoutSelector}]`) as HTMLElement;

    switch (true) {
      case shouldBeSkipped(Target):
        break;

      case Boolean(parentClickout):
        ev.preventDefault();
        standardClickout(parentClickout);
        break;

      default:
        break;
    }
  }

  function standardClickout(element: HTMLElement) {
    const couponUrl = element.dataset.couponUrl || null;
    const href = element.getAttribute('href');
    const changedBehaviour = element.dataset.changedBehaviour;
    const noCookieDrop = element.dataset.noCookie;

    let newTabHref: string | null;
    let sameTabHref: string | null;

    if (noCookieDrop) {
      newTabHref = null;
      sameTabHref = href;
    } else if (changedBehaviour) {
      newTabHref = couponUrl;
      sameTabHref = href;
    } else {
      newTabHref = window.location.pathname + href;
      sameTabHref = couponUrl;
    }

    if (newTabHref) {
      window.open(newTabHref);
    }

    location.href = sameTabHref || "";

    if (changedBehaviour || noCookieDrop) {
      window.location.reload();
    }
  }

  return {
    run: init
  }
})()
