export function shouldBeSkipped(element: HTMLElement): boolean {
  let skip = false;
  const hostName = document.location.hostname;
  const referrer = document.referrer ? new URL(document.referrer).hostname : 'null';
  const parent = element.closest('[data-area]') as HTMLElement;
  const parentArea = parent?.dataset?.area || 'MB';

  if (hostName === referrer && parentArea === "MOD") {
    skip = true;
  }

  return skip;
}

export function getPageType(): string {
  const pageTypeRaw = document.body.dataset.init as string || "empty";

  enum pageTypesList {
    "home_index" = "homepage",
    "shops_show" = "shop_page",
    "searches_index" = "search_page",
    "coupons_index" = "listing_page",
    "categories_show" = "category_page",
    "campaigns_show" = "campaign_page",
    "pages_show" = "static_page",
    "frontend_not_found" = "404_page",
    "empty" = "empty"
  }

  return pageTypesList[pageTypeRaw as keyof typeof pageTypesList];
}
