/* global rootDir */
import mediator from "libs/mediator";
import log from "libs/Utils/log";
import axios from "axios";

export default (function() {
  function init() {
    log("CouponVote:init");
    const couponVotes = document.querySelectorAll('[data-module="coupon-vote"]');

    couponVotes.forEach((couponVote) => {
      if (couponVote) {
        let buttons = couponVote.querySelectorAll('[data-vote-button]');
        buttons.forEach(function(button) {
          button.addEventListener("click", function() {
            axios
              .get(rootDir + '/coupons/vote', {
                params: {
                  id: button.dataset.id,
                  type: button.dataset.type
                }
              })
              .then(function() {
                button.classList.add("active");
                const voteMessage = couponVote.querySelector("[data-vote-message]");

                if (voteMessage) {
                  voteMessage.innerHTML = voteMessage.dataset['voteMessage' + button.dataset.type[0].toUpperCase() + button.dataset.type.slice(1)];
                  voteMessage.classList.remove('hidden');
                }
              })
              .catch(err => console.error(err));
          });
        })
      }
    })
  }

  function run() {
    mediator.on("coupon-vote", init);
  }

  return {
    run: run
  };
})();
