import mediator from "libs/mediator";
import log from "../../Utils/log";
import ClipboardJS from "clipboard";
import QRCode from 'qrcode';
import axios from 'axios';
import CSRFManager from "../../Tracking/CSRFManager";

export default (function() {
  let Modal = document.querySelector("#my-modal .modal__content");

  function copyButton() {
    let copyButton = Modal.querySelector('.modal-clickout__copy');

    if (copyButton) {
      let couponAttribute = copyButton.getAttribute('data-clipboard-target');
      let copyText = Modal.querySelector(couponAttribute);
      let clipboard = new ClipboardJS(copyButton);
      let message = Modal.querySelector('.modal__message');

      clipboard.on('success', function(ev) {
        log('Copied:', ev.text);

        copyText.classList.add(`${couponAttribute.slice(1)}--copied`);
        copyButton.classList.add('modal-clickout__copy--copied')

        if (message) {
          message.classList.add('modal__message--copied');
        }

        copyButton.querySelector('.copy-text').classList.add('hidden');
        copyButton.querySelector('.copied-text').classList.remove('hidden');
        copyButton.setAttribute('disabled', 'disabled');

        ev.clearSelection();

        if (Modal.querySelector('[data-module="coupon-vote"]')) {
          document.addEventListener("visibilitychange", handleVisibilityChange);
        }

        showCode(copyButton);
      });
    }
  }

  function showCode(copyButton) {
    const codeEl = copyButton?.closest(".modal")?.querySelector('[data-coupon-code-blur]');

    if(codeEl) {
      codeEl.removeAttribute('data-coupon-code-blur');
    }
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      document.querySelector('.modal-body').classList.add("hidden");
      document.querySelector('.modal-footer').classList.add("modal-footer__vote-coupon");
    }
  }

  function generateQRCode(clickoutCode) {
    let options = {
      errorCorrectionLevel: 'H',
      type: 'image/png',
      scale: 10,
      margin: 5,
      width: 220,
    };

    if (clickoutCode) {
      QRCode.toCanvas(clickoutCode, clickoutCode.dataset.promocode, options, function(error) {
        if (error) console.error(error);
      });
    }
  }

  function saveQRCode() {
    let QRWrap = Modal.querySelector("[data-qr-code-wrap]");

    if(QRWrap) {
      let code = Modal.querySelector("[data-coupon-code]").innerText;
      let saveButton = QRWrap.querySelector("[data-qr-btn]");
      let canvas = document.getElementById("clickoutCode");
      let ctx = canvas.getContext("2d");

      saveButton.addEventListener('click', ()=> {
        ctx.font = "22px serif";
        ctx.fillText(`${code}`, 30, 20);
        let imageUrl = canvas.toDataURL("image/jpg", 1.0);

        saveButton.href= imageUrl;
      })
    }
  }

  function handleModalESDOButton() {
    let esdoButton = document.getElementById('esdo_button');

    if (esdoButton) {
      let esdoForm = document.querySelector('.esdo-form');
      let message = document.getElementById('messageESDO');
      let esdoCouponId;
      let esdoMail;
      let authToken;
      let esdoCheckbox = document.getElementById('esdo_form_checkbox');

      esdoButton.addEventListener('click', () => {
        if (esdoForm) {
          esdoCouponId = esdoForm.querySelector('input[name="id"]').value;
          esdoMail = esdoForm.querySelector('input[name="email"]').value;
          authToken = window.csrf_token;
        }

        if (esdoMail !== '' && emailIsValid(esdoMail) && esdoCheckbox.checked) {
          message.querySelector('.esdo-form__message-empty').classList.remove('show');
          esdoButton.classList.add('disabled');

          axios
            .post(document.querySelector('form.esdo-form').action, {
              id: esdoCouponId,
              email: esdoMail,
              authenticity_token: authToken
            })
            .then(() => {
              esdoForm.style.display = "none";
              document.querySelector('.sent-code-message').classList.add('show');
            })
            .catch(err => {
              message.querySelector('.esdo-form__message-incorrect').classList.add('show');
              esdoButton.classList.remove('disabled');
              console.error(err);
            });
        } else {
          message.querySelector('.esdo-form__message-empty').classList.add('show');
        }
      });

      esdoMail = esdoForm.querySelector('input[name="email"]');

      esdoMail.addEventListener('click', () => {
        message.querySelector('.esdo-form__message-empty').classList.remove('show');
        message.querySelector('.esdo-form__message-incorrect').classList.remove('show');
      });

      if (esdoCheckbox) {
        esdoCheckbox.addEventListener('change', () => {
          esdoButton.classList.contains('disabled') ? esdoButton.classList.remove('disabled') : esdoButton.classList.add('disabled');
        });
      }
    }

    function emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
  }

  function generateModalUrl(couponId) {
    let url;
    if(window.embed){
      url = window.embed + '/modals/embed_clickout?id=' + couponId;
    } else {
      url = window.rootDir + '/modals/coupon_clickout?id=' + couponId;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const clickoutPageType = urlParams.get('clickout_page_type');
    const clickoutPageId = urlParams.get('clickout_page_id');

    if (clickoutPageType && clickoutPageId) {
      url += '&clickout_page_type=' + encodeURIComponent(clickoutPageType);
      url += '&clickout_page_id=' + encodeURIComponent(clickoutPageId);
    }

    return url;
  }

  function showModal() {
    if (window.location.hash.length && window.location.hash.split("-")[0] === '#id') {
      let couponId = window.location.hash.split("-")[1];
      let url = generateModalUrl(couponId);

      CSRFManager.ensureCSRFToken.then(() => {
        let options = {
          headers: {
            'X-CSRF-Token': window.csrf_token
          }
        };

        let couponElement = document.querySelector(`[href="#id-${couponId}"]`);
        if (couponElement && couponElement.getAttribute('data-unique') == 'true') {
          options.headers['Unique-Coupon-Modal'] = 'true';
        }

        axios
          .post(url, {}, options)
          .then(function(response) {
            Modal.insertAdjacentHTML('beforeend', response.data);
            copyButton();
            generateQRCode(document.getElementById('clickoutCode'));
            saveQRCode();
            handleModalESDOButton();
            mediator.trigger("modal:show");
            mediator.trigger("showmore");
            mediator.trigger("coupon-vote");
          })
          .catch(function(err) {
            log('Failed to fetch page: ', err);
          });
      });
    }
  }

  function run() {
    log("ModalClickout:init");
    showModal();
  }

  return {
    run: run
  };
})();
