import log from "../Utils/log";

export default (function() {
  function showMoreInformation() {
    let buttons = document.querySelectorAll("[data-more-button]");

    buttons.forEach((button) => {
      const textContainer = button.closest('[data-more-text-container]'),
        modifier = textContainer.dataset.moreTextModifier,
        shopTextTruncated = textContainer.querySelector("[data-more-text-truncated]"),
        shopTextFull = textContainer.querySelector("[data-more-text-full]"),
        moreText = button.dataset.moreText,
        lessText = button.dataset.lessText;

      button.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (shopTextFull) {
          if (shopTextFull.classList.contains(modifier)) {
            shopTextFull.classList.remove(modifier);
            shopTextTruncated.classList.add(modifier);
            button.innerText = lessText;
          } else {
            shopTextFull.classList.add(modifier);
            shopTextTruncated.classList.remove(modifier);
            button.innerText = moreText;
          }
        }

      });
    });
  }

  function run() {
    log("Shop module");
    showMoreInformation();
  }

  return {
    run: run
  };
})();
