export default (function() {

  function run() {
    const jumpToLink = document.querySelectorAll('.jump-to-links__link');
    const offsetElement = document.querySelector("[data-offset]");
    let offsetTop = 0;

    if (offsetElement) {
      offsetTop = offsetElement.getBoundingClientRect().height;
    }

    jumpToLink.forEach(el => {
      el.addEventListener('click', (ev) => {
        let elementToScroll;
        ev.preventDefault();
        let linkAnchor = el.getAttribute('href');
        let elements = document.querySelectorAll(linkAnchor);

        for (let i = 0, length = elements.length; i < length; i++) {
          if (isVisible(elements[i])) {
            elementToScroll = elements[i];
          }
        }

        let scrollTo = elementToScroll.getBoundingClientRect().top + window.scrollY - offsetTop;

        window.scrollTo({
          top: scrollTo,
          behavior: "smooth"
        })

        elementToScroll.classList.add('seo-text__title--highlighted');

        setTimeout(() => {
          elementToScroll.classList.remove('seo-text__title--highlighted');
        }, 2000);
      });
    });
  }

  function isVisible(el) {
    return el.getBoundingClientRect().width !== 0 && !!el.getBoundingClientRect().height !== 0;
  }

  return {
    run: run
  };
})();
