import TrackingEvent from "../TrackingEvent.interface";
import Utils from "../../Utils";
import BaseProvider from "./BaseProvider";

type gaEvent = {
  "event": string,
  "eventCategory": string,
  "eventAction": string,
  "eventLabel": string,
  "eventValue": string
}

class GaTracking extends BaseProvider {
  private getActionString(eventData: TrackingEvent): string {
    const action = decodeURIComponent(eventData.tracking_data || "null");
    const area = eventData.coupon_area;
    const element = eventData.coupon_element;
    const position = eventData.coupon_position;
    const couponID = eventData.coupon_id;
    const bannerId = eventData.banner_id;

    let actionArray = [action, area, element, position, couponID, bannerId];
    actionArray = actionArray.filter(item => {
      return !!item;
    });

    return actionArray.join("/");
  }

  protected isLoaded(): boolean {
    return typeof window.ga !== 'undefined';
  }

  protected prepareEvent(eventData: TrackingEvent): gaEvent {
    let eventCategory = 'misc';
    let eventLabel = eventData.coupon_title || "null";
    const shopName = eventData.shop_name || "null";

    switch (eventData.type) {
      case "clickout":
        eventCategory = 'Clickout - ' + Utils.unescape(shopName.toLowerCase());
        break;
      case "newsletter":
        eventCategory = "Newsletter";
        break;
      case "non-clickout":
        eventCategory = "Clickout - Fallback URL";
        eventLabel = eventData.tracking_label || "null";
        break;
      case "banner":
        eventCategory = "Clickout - Banner";
        eventLabel = eventData.banner_theme || "";
        break;
      case "misc":
        eventCategory = "misc";
        eventLabel = eventData.tracking_label || "null";
        break;
    }

    const Event: gaEvent = {
      "event": eventData.type,
      "eventCategory": eventCategory,
      "eventAction": this.getActionString(eventData),
      "eventLabel": eventLabel,
      "eventValue": "25"
    };

    return Event;
  }

  sendProviderEvent(eventData: TrackingEvent) {
    if (this.isLoaded()) {
      const Event = this.prepareEvent(eventData);
      window.eventLayer.push(Event);
    }
  }
}

const gaTracker = new GaTracking();

export default gaTracker;
