import axios from "axios";
import Utils from "../../Utils";

export default (function() {
  let searchForms = document.querySelectorAll('[data-widget="search"]');
  let searchInput, searchHeaderResults, submitBtn, submitBtnActiveClass, searchInputActiveClass;

  function init() {
    if (searchForms.length) {
      searchForms.forEach(function(searchForm) {
        submitBtn = searchForm.querySelector("[data-search-submit]");
        submitBtn.addEventListener("click", (el) => { showSearchBox(el, searchForm); });
        suggestions(searchForm);
      });
    }
  }

  function suggestions(searchForm) {
    searchForm.addEventListener('submit', function(ev) {
      selectShopFromSearchResults(ev, searchForm);
      return null;
    });

    searchHeaderResults = document.querySelector(`${searchForm.dataset.searchResultSelector}[data-search-result]`);
    searchInput = searchForm.querySelector('[data-search-input]');

    searchInput.addEventListener('keyup', function(ev) {
      searchForm = this.closest('[data-widget="search"]');
      searchHeaderResults = document.querySelector(`${searchForm.dataset.searchResultSelector}[data-search-result]`);

      if (ev.keyCode === 38 || ev.keyCode === 40 || ev.keyCode === 27) {
        navigateOnSearchResults(ev.keyCode, searchHeaderResults);
        return;
      }

      clearTimeout(this.dataset.timer);

      if (this.value.length === 0) {
        searchHeaderResults.classList.add('hidden');
      } else {
        this.dataset.timer = setTimeout(() => {
          axios
            .get(this.dataset.autocompleteUrl, {
              params: {
                query: this.value
              }
            })
            .then(function(response) {
              let data = response.data;
              if (data.length > 0) {
                searchHeaderResults.innerHTML = data;
                searchHeaderResults.classList.remove('hidden');
              } else {
                searchHeaderResults.innerHTML = '';
                searchHeaderResults.classList.add('hidden');
              }
            });
        }, 300);
      }
    });

    searchInput.addEventListener('focusout', function() {
      searchForm = this.closest('[data-widget="search"]');
      searchHeaderResults = document.querySelector(`${searchForm.dataset.searchResultSelector}[data-search-result]`);
      setTimeout(function() {
        searchHeaderResults.classList.add('hidden');
      }, 300);
    });
  }

  function navigateOnSearchResults(key, searchHeaderResults) {
    let currentItem, itemList, lastItem;
    itemList = searchHeaderResults.querySelectorAll('ul > li');
    currentItem = searchHeaderResults.querySelector('.active');
    lastItem = searchHeaderResults.querySelector('ul > li:last-child');

    if (itemList != null) {
      switch (key) {
        case 38:
          if (currentItem !== null && itemList.length > 1 && currentItem.previousElementSibling !== undefined && currentItem.previousElementSibling !== null) {
            currentItem.classList.remove('active');
            currentItem.previousElementSibling.classList.add('active');
          } else if (currentItem == null && itemList.length > 1) {
            lastItem.classList.add('active');
          }
          break;
        case 40:
          if (currentItem !== null && itemList.length > 1 && currentItem.nextElementSibling !== undefined && currentItem.nextElementSibling !== null) {
            currentItem.classList.remove('active');
            currentItem.nextElementSibling.classList.add('active');
          } else if (currentItem === null && itemList.length > 1) {
            itemList[0].classList.add('active');
          }
          break;
        case 27:
          if (currentItem !== null && currentItem !== undefined) {
            currentItem.classList.remove('active');
          }
          searchHeaderResults.classList.add('hidden');
          break;
      }
    }
  }

  function selectShopFromSearchResults(ev, searchForm) {
    let currentItem, url;
    searchHeaderResults = document.querySelector(`${searchForm.dataset.searchResultSelector}[data-search-result]`);
    currentItem = searchHeaderResults.querySelector('.active');
    if (currentItem !== null && currentItem !== undefined) {
      ev.preventDefault();
      url = currentItem.querySelector('a').href;
      window.location.href = url;
    }
  }

  function showSearchBox(ev, searchForm) {
    searchInput = searchForm.querySelector('[data-search-input]');
    submitBtn = searchForm.querySelector('[data-search-submit]');
    submitBtnActiveClass = submitBtn.dataset.active;
    searchInputActiveClass = searchInput.dataset.active;

    function expandSearchBox() {
      if (!submitBtn.classList.contains(submitBtnActiveClass)) {
        ev.preventDefault();
      }

      submitBtn.classList.add(submitBtnActiveClass);
      searchInput.classList.add(searchInputActiveClass);
      setTimeout(() => {
        searchInput.focus();
      }, 300);
      document.addEventListener("click", (el) => { clickOutside(el, searchForm); });
    }

    expandSearchBox();
  }

  function closeSearchBox(searchForm) {
    searchInput = searchForm.querySelector('[data-search-input]');
    submitBtn = searchForm.querySelector("[data-search-submit]");
    submitBtnActiveClass = submitBtn.dataset.active;
    searchInputActiveClass = searchInput.dataset.active;

    submitBtn.classList.remove(submitBtnActiveClass);
    searchInput.classList.remove(searchInputActiveClass);
    document.removeEventListener("click", clickOutside);
  }

  function clickOutside(ev, searchForm) {
    if (!ev.target.closest('[data-widget="search"]')) {
      closeSearchBox(searchForm);
    }
  }

  function run() {
    Utils.log("Search module");

    document.addEventListener('tracking_user_set', function() {
      init();
    });
  }

  return {
    run: run
  };
})();
