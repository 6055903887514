import isMobile from 'libs/Utils/isMobile';
import log from "libs/Utils/log";

export default (function() {
  let showMore;
  const isFilterMobile = isMobile.mobile() || isMobile.tablet();
  const filter = document.querySelector('[data-module="coupon-filter"]');
  const couponsGridSelector = '[data-coupons-grid]';
  const couponsGrid = document.querySelector(couponsGridSelector);
  const settingsCouponsGrid = {initialSize: 12, pageSize: 8};
  let couponsContainer = document.querySelector('[data-coupons-list]');
  const couponsNewsletter = document.querySelector('.coupon--newsletter');
  const couponsEarthmarkRating = document.querySelector('.coupons__earthmark-rating');

  if (couponsGrid) {
    showMore = couponsGrid.querySelector('.show-more');
  }

  function getCouponsContainer() {
    return couponsContainer || couponsGrid;
  }

  function run() {
    if (filter) {
      log("CouponFilter:init");
      const filterItems = filter.querySelectorAll("[data-filter-item]");

      couponsContainer = getCouponsContainer();

      filterItems.forEach(initItem);

      checkCouponsCount();
    }

    initShowMoreGrid();
    initShowMoreClickHandler();
    handlerToggleCuratedPart();
  }

  function initItem(item) {
    let filterItemType = item.dataset.filterType;
    handleFilterClick(item);

    if (filterItemType === 'all' || filterItemType === "extended") {
      return;
    }

    let filterType = 'Desktop';
    let filterTitleType = 'Item';

    if (isFilterMobile) {
      filterType = 'Mobile';
    }

    let itemSize = item.dataset[`filter${filterType}ItemSize`];

    if (itemSize <= 0) {
      item.classList.add('hidden');
      return;
    }

    if (itemSize > 1) {
      filterTitleType = 'Items';
    }

    let itemTitle = item.dataset[`filter${filterTitleType}Title`];

    item.innerHTML = itemSize + ' ' + itemTitle;
  }

  function checkCouponsCount() {
    let countItem = filter.querySelector("[data-filter-show-coupons-count]");
    let activeItems = filter.querySelectorAll("[data-filter-item]:not(.hidden):not([data-filter-type='all'])");
    let filterTitleType = 'Item';

    if (activeItems.length <= 1) {
      activeItems.forEach(function(item) {
        item.classList.add('hidden');
      });

      let count = countItem.dataset.filterDesktopItemSize;

      if (isFilterMobile && couponsContainer) {
        count = countItem.dataset.filterMobileItemSize;
      }

      if (count > 1) {
        filterTitleType = 'Items';
      }

      countItem.innerHTML = count + ' ' + countItem.dataset[`filter${filterTitleType}Title`];
    }
  }

  function initShowMoreGrid() {
    if (!couponsGrid) {
      return;
    }

    let couponForShow = couponsGrid.querySelectorAll('li.item[data-visible=true]');
    let lastCouponForShow = couponForShow[couponForShow.length - 1];

    if (couponForShow.length > settingsCouponsGrid.initialSize) {
      lastCouponForShow = couponForShow[settingsCouponsGrid.initialSize - 1];
    }

    let startIndex = parseInt(lastCouponForShow.getAttribute('data-index')) + 1;
    let couponsForHide = couponsGrid.querySelectorAll('li.item[data-visible=true]:nth-of-type(n+' + (startIndex + 1) + ')');

    couponsForHide.forEach(function(coupon) {
      coupon.classList.add('hidden');
    });

    let couponForShowCount = couponsGrid.querySelectorAll("[data-coupon-item][data-visible=true]").length;

    showMore.classList.add('hidden');

    if (couponForShowCount > settingsCouponsGrid.initialSize) {
      showMore.classList.remove('hidden');
    }
  }

  function initShowMoreClickHandler() {
    if (!couponsGrid) {
      return;
    }

    showMore.addEventListener("click", handleShowMoreClick);
  }

  function handleShowMoreClick() {
    let firstCouponForShow = couponsGrid.querySelector('li.item.hidden[data-visible=true]');
    let initialSize = parseInt(firstCouponForShow.dataset.index) + settingsCouponsGrid.pageSize;

    let couponsForShow = couponsGrid.querySelectorAll('li.item.hidden[data-visible=true]:nth-of-type(-n+' + initialSize + ')');

    couponsForShow.forEach(function(coupon) {
      coupon.classList.remove('hidden');
    });
    let couponsShowed = couponsGrid.querySelector('li.item.hidden[data-visible=true]');

    if (!couponsShowed && showMore) {
      showMore.classList.add('hidden');
    }
  }

  function handleFilterClick(filterItem) {
    if (!filter) {
      return;
    }

    const filterItems = filter.querySelectorAll("[data-filter-item]");

    filterItem.addEventListener("click", function(event) {
      let filterType = filterItem.dataset.filterType;
      event.preventDefault();

      filterItems.forEach(function(item) {
        item.classList.remove('active');
      });
      filterItem.classList.add('active');

      filterCoupons(filterType);
    });
  }

  function filterCoupons(filterType = 'all') {
    if (couponsContainer) {
      let allCoupons = couponsContainer.querySelectorAll('[data-coupon-item]');
      let couponsByType = document.querySelectorAll(`[data-coupon-item][data-coupon-type='${filterType}']:not([data-coupon-type-is-expired])`);
      allCoupons.forEach(function(element) {
        element.setAttribute('data-visible', 'false');
      });

      if (filterType === 'extended') {
        return;
      } else if (filterType === 'all') {
        allCoupons.forEach(function(element) {
          element.setAttribute('data-visible', 'true');
        });
      } else {
        couponsByType.forEach(function(element) {
          element.setAttribute('data-visible', 'true');
        });
      }

      resetCoupons();
      setCouponsOrder();
      setPositionForNotCouponElement(couponsNewsletter, filterType, couponsByType);
      setPositionForNotCouponElement(couponsEarthmarkRating, filterType, couponsByType);
    }
  }

  function resetCoupons() {
    let forHideCoupons = couponsContainer.querySelectorAll('[data-coupon-item][data-visible=false]');
    let forShowCoupons = couponsContainer.querySelectorAll('[data-coupon-item][data-visible=true]');

    forHideCoupons.forEach(function(coupon) {
      coupon.classList.add('hidden');
    });
    forShowCoupons.forEach(function(coupon) {
      coupon.classList.remove('hidden');
    });

    initShowMoreGrid();
    handlerToggleCuratedPart();
  }

  function setPositionForNotCouponElement(element, filterType, couponsByType) {
    if (couponsContainer && element) {
      const elementPosition = element.dataset.position - 1;
      element.style.order = parseInt(elementPosition) + 1;

      if (filterType === 'all') {
        couponsContainer.classList.remove(`${couponsContainer.classList[0]}--filtered`);
      } else {
        if (couponsByType[elementPosition]) {
          element.style.order = parseInt(couponsByType[elementPosition].dataset.index) + 1;
        }

        couponsContainer.classList.add(`${couponsContainer.classList[0]}--filtered`);
      }
    }
  }

  function setCouponsOrder() {
    if (couponsContainer) {
      const allCoupons = couponsContainer.querySelectorAll('[data-coupon-item]');
      allCoupons.forEach(coupon => {
        coupon.style.order = coupon.dataset.index;
      });
    }
  }

  function handlerToggleCuratedPart() {
    const curatedPart = couponsContainer?.querySelector('[data-curated-list]');

    if(!curatedPart) {
      return;
    }

    const curatedCupons = couponsContainer.querySelectorAll('.curated__coupon-list [data-coupon-item]');
    const hiddenCuratedCoupons = couponsContainer.querySelectorAll('.curated__coupon-list [data-coupon-item][data-visible=false]');

    if(hiddenCuratedCoupons.length === curatedCupons.length) {
      curatedPart.classList.add('hidden');
    } else {
      curatedPart.classList.remove('hidden');
    }

  }

  return {
    run: run
  };
})();
