import Utils from "../Utils";

export default (function() {
  const columnsFeatured = document.querySelectorAll('[data-widget="featuredCoupons"]');

  function countdown() {
    columnsFeatured.forEach(column => {
      let timeEl = column.querySelectorAll('[data-time]');

      if (timeEl) {
        timeEl.forEach(el => {
          let time = el.dataset.time;

          Utils.countdown(el, {date: time});
        });
      }
    })
  }

  return {
    run: countdown
  };
})();
