import Zvezdochki from "@savingsunited/zvezdochki/src/Zvezdochki";
import axios from "axios";
import log from "../Utils/log";

export default (function() {
  function init() {
    let starRatingEl = document.querySelector(".star-rating");
    let rootDir = window.rootDir;

    let starsOptions = {};

    if (starRatingEl) {
      log('Vote:init');

      starsOptions = {
        ratingDataAttr: "votes",
        id: starRatingEl.dataset.shopId,
        localStorageName: 'votes',
        activeClass: "star-rating__item--active",
        votedClassName: 'star-rating--voted'
      };

      new Zvezdochki(starRatingEl, starsOptions);

      let shopId = starRatingEl.dataset.shopId;

      starRatingEl.addEventListener('vote', (ev) => {
        starRatingEl.classList.add('load');

        axios
          .get(rootDir + '/shops/vote', {
            params: {
              id: shopId,
              stars: ev.detail.star
            }
          })
          .then(function(response) {
            starRatingEl.classList.remove('load');

            let answerEl = starRatingEl.querySelector("p.bold-text");
            answerEl.innerText = response.data['answer'];
          })
          .catch(err => console.error(err));
      });
    }
  }

  function run() {
    init();
  }

  return {
    run: run
  };
})();
