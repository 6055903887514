import sendEvents from "../sendEvents";
import buildClickoutEvents from "../buildClickoutEvent";

export default function standardClickout(element: HTMLElement) {
  const couponUrl = element.dataset.couponUrl || null;
  const href = element.getAttribute('href');
  const changedBehaviour = element.dataset.changedBehaviour;
  const noCookieDrop = element.dataset.noCookie;

  let newTabHref: string | null;
  let sameTabHref: string | null;

  if (noCookieDrop) {
    newTabHref = null;
    sameTabHref = href;
  } else if (changedBehaviour) {
    newTabHref = couponUrl;
    sameTabHref = href;
  } else {
    newTabHref = window.location.pathname + href;
    sameTabHref = couponUrl;
  }

  sendEvents(buildClickoutEvents(element));

  if (newTabHref) {
    window.open(newTabHref);
  }

  location.href = sameTabHref || "";

  if (changedBehaviour || noCookieDrop) {
    window.location.reload();
  }
}
