export default (function initializeProgressBar() {
  const progressBar = document.querySelector(".read-progress-bar__indicator");
  if (!progressBar) return;
  document.addEventListener("DOMContentLoaded", progressBarSet);
  document.addEventListener("scroll", () => {
    window.requestAnimationFrame(progressBarSet);
  });

  function progressBarSet() {
    const scrollableHeight = document.body.scrollHeight - window.innerHeight;
    const percentageScrolled = (window.scrollY / scrollableHeight) * 100 - 100;

    if (window.scrollY == 0) {
      progressBar.style.transform = `translate3d(0, 0, 0)`;
      progressBar.style.height = 0;
    } else {
      progressBar.style.transform = `translate3d(${percentageScrolled}%, 0, 0)`;
      progressBar.style.height = '7px';
    }
  }

  function run() {
    initializeProgressBar();
  }

  return {
    run: run,
  };
})();
