import standardClickout from "libs/Tracking/clickTypes/standardClickout";
import trackNonClickoutLinks from "libs/Tracking/clickTypes/trackNonClickoutLinks";
import trackEarthMark from "libs/Tracking/clickTypes/earthMark";
import trackByCategoryEvent from "./clickTypes/byCategoryEvent";
import trackClickEvent from "./clickTypes/clickEvent";
import {shouldBeSkipped} from "./helpers";
import trackNewsletter from "libs/Tracking/clickTypes/newsletter";


export default (function() {
  const clickoutSelector = "data-coupon-url";

  function init() {
    document.addEventListener("click", function(ev: MouseEvent) {
      defineAndTrackClick(ev);
    });
  }

  function defineAndTrackClick(ev: MouseEvent) {
    const Target = ev.target as HTMLElement;
    const parentClickout = Target.closest(`[${clickoutSelector}]`) as HTMLElement;
    const parentElement = Target.closest("[data-element]") as HTMLElement;
    const noParent = Target.dataset.element;
    const eventCategory = (Target.closest("[data-event-category]") as HTMLElement)?.dataset.eventCategory;
    const isEarthMark = Target.closest("[data-earthmark]");
    const isNewsletter = Target.closest("[data-newsletter]");
    const isBanner = Target.closest("[data-banner]");
    const isDonationCategory = !!eventCategory;
    const isComingSoonMOD = !!Target.closest("[data-coming-soon]");

    switch (true) {
      case isComingSoonMOD:
        trackClickEvent(Target);
        break;
      case shouldBeSkipped(Target):
        break;

      case Boolean(parentClickout):
        ev.preventDefault();
        standardClickout(parentClickout);
        break;

      case Boolean(isNewsletter):
        trackNewsletter(Target);
        break;

      case Boolean(isEarthMark):
        trackEarthMark(Target);
        break;

      case Boolean(isDonationCategory):
        trackByCategoryEvent(Target, eventCategory as "donation");
        break;

      case Boolean(isBanner && !isDonationCategory):
        trackClickEvent(Target);
        break;

      case Boolean(parentElement):
        trackNonClickoutLinks(parentElement);
        break;

      case Boolean(noParent):
        trackNonClickoutLinks(Target);
        break;

      default:
        break;
    }
  }

  return {
    run: init
  };
})();
