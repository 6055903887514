import mediator from "libs/mediator";
import Utils from "../../Utils/";

export default (function() {
  let startDate, endDate, currentDate, id, countdownDate, stickyBar, stickyClose, cookieName, cookieLifetimeDays, clickedBanners, lifetime, siteId, mainFooter, footer;
  const notice = document.querySelector(".cookies-eu");

  if (notice) {
    siteId = notice.dataset.siteId;
  }

  let bannerHidden = false;

  if (window.sticky_banner) {
    ({startDate = new Date(startDate), endDate, id, countdownDate} = window.sticky_banner);

    startDate = new Date(startDate).toISOString();
    endDate = new Date(endDate).toISOString();

    if (countdownDate) {
      countdownDate = new Date(countdownDate);
    }

    currentDate = new Date().toISOString();
    stickyBar = document.querySelector('.sticky-bar');
    stickyClose = stickyBar.querySelector('.sticky-bar__close');
    cookieName = `sticky-bars`;
    clickedBanners = JSON.parse(readBannerClicks());
    cookieLifetimeDays = 1;
    lifetime = 1000 * 60 * 60 * 24 * cookieLifetimeDays;
    mainFooter = document.querySelector("footer.main");
    footer = document.querySelector("[data-footer]")
  }

  function hide() {
    stickyBar.style.transform = "translateY(225px)";
    stickyBar.classList.remove("sticky-bar--opened");
    bannerHidden = true;

    addBannerClick(id);
  }

  function addBannerClick(id) {
    let clicks = clickedBanners;

    let newClick = {};
    newClick[id] = bannerClickExpirationDate();

    Object.assign(clicks, newClick);

    writeBannerCookie(JSON.stringify(clicks));
  }

  function removeBannerClick(id) {
    let clicks = clickedBanners;
    delete clicks[id];

    writeBannerCookie(JSON.stringify(clicks));
  }

  function writeBannerCookie(string) {
    let secureFlag = (document.location.protocol === 'https:') ? "Secure" : '';
    let expiresAt = new Date(bannerClickExpirationDate()).toGMTString();

    document.cookie = cookieName + "=" + string + "; expires=" + expiresAt + "; path=/" + '; ' + secureFlag;
  }

  function readBannerClicks() {
    const cookie = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

    return cookie ? cookie.pop() : '{}';
  }

  function moveAboveCookieNotice() {
    if (!notice) {
      return
    }

    if (notice.style.display !== "none" && siteId != 93) {
      stickyBar.style.transform = `translateY(-${notice.offsetHeight}px)`;
    }
    stickyBar.classList.add("sticky-bar--opened");
  }

  function isExpired(date) {
    return new Date(date) < Date.now();
  }

  function bannerClickExpirationDate() {
    let date = new Date();

    return new Date(date.getTime() + lifetime);
  }

  function adjustFooterMargin(el) {
    if (!bannerHidden) {
      el.style.marginBottom = `${stickyBar.offsetHeight}px`;
    } else {
      el.style.marginBottom = 0;
    }
  }

  function showBanner() {
    if (stickyBar) {
      stickyBar.style.transform = "translateY(0)";

      stickyBar.addEventListener("transitionend", function() {
        mediator.trigger("sticky_banner");

        if (mainFooter) {
          adjustFooterMargin(mainFooter)
        } else if (footer) {
          adjustFooterMargin(footer)
        }
      });
    }
  }

  function run() {
    Utils.log("Banners init");

    if (id) {
      let bannerClickExpired = typeof clickedBanners[id] !== 'undefined' && isExpired(clickedBanners[id])

      if ((!clickedBanners[id] || bannerClickExpired) && currentDate < endDate && currentDate > startDate) {
        mediator.on("cookies_eu:close", function() {
          showBanner();
        });

        document.body.addEventListener("cookies_eu:close", () => {
          if (siteId != 93 && !bannerHidden) {
            showBanner();
          }
        });

        if (typeof countdownDate !== 'undefined' && currentDate < countdownDate) {
          Utils.countdown(stickyBar, {date: countdownDate});
        }

        if (bannerClickExpired) {
          removeBannerClick(id);
        }

        stickyClose.addEventListener("click", function(ev) {
          ev.preventDefault();
          ev.stopPropagation();
          hide();
          mediator.trigger("banner:hide");
        });

        setTimeout(
          function() {
            showBanner();
            moveAboveCookieNotice();
            mediator.trigger("banner:show");
          }, 1000
        );
      }
    }
  }

  return {
    run: run
  };
})();
