import BaseProvider from "./BaseProvider";
import TrackingEvent from "../TrackingEvent.interface";
import {getPageType} from "../helpers";
const Piano = window.pa;


type pianoEvent = {
  click?: string,
  page_type?: string,
  coupon_id?: string,
  coupon_area?: string,
  shop_id?: string,
  shop_id_landing?: string,
  coupon_position?: string,
  coupon_element?: string,
  goal_type?: string
}

class PianoTracking extends BaseProvider {
  constructor() {
    super();
    if (this.isLoaded()) {
      Piano.privacy.include.properties("*", 'exempt');
      Piano.consent.setMode(Piano.consent.getMode() || 'optout');
      Piano.setConfigurations({
        ...(window.paConfig) && {'site': window.paConfig.site},
        ...(window.paConfig) && {'collectDomain': window.paConfig.collectDomain},
      });

      const pageType = getPageType();
      this.onPageShow(pageType);
      this.setPrivacyFromCMP();
    }
  }

  protected onPageShow(pageType: string) {
    Piano.setProperties({
      'page': location.href,
      'page_type': pageType,
      'site_id': window.eventLayer[0].site_id
    }, {
      'persistent': true,
      'events': ['page.*', 'click.*']
    });

    if (pageType === 'shop_page') {
      Piano.sendEvent("page.display", {'shop_id': window.eventLayer[0].shop_id});
    } else {
      Piano.sendEvent("page.display", {});
    }
  }

  protected isLoaded(): boolean {
    return typeof Piano !== 'undefined';
  }

  protected prepareEvent(eventData: TrackingEvent): pianoEvent {
    const readyEvent: pianoEvent = {
      click: eventData.type,
      coupon_id: eventData.coupon_id,
      coupon_area: eventData.coupon_area,
      shop_id: eventData.shop_id,
      shop_id_landing: eventData.shop_id_landing,
      coupon_position: eventData.coupon_position,
      coupon_element: eventData.coupon_element,
      ...(eventData.type === 'clickout') && {goal_type: 'coupon_clickout'},
    };

    if (eventData.type === 'clickout' || eventData.type === 'non-clickout') {
      return readyEvent;
    } else {
      return {}
    }
  }

  sendProviderEvent(Event: TrackingEvent) {
    if (this.isLoaded()) {
      Piano.sendEvent("click.action", this.prepareEvent(Event));
    }
  }

  setPrivacyFromCMP(){
    document.addEventListener('gdprConsentGiven', () => {
      if(window.gdpr){
        const positive = window.gdpr.getPurposes()['analytics'];
        if(positive) {
          Piano.consent.setMode('optin');
        } else {
          Piano.consent.setMode('exempt');
        }
      }
    });
  }
}

const pianoTracker = new PianoTracking();

export default pianoTracker;
