import log from "../Utils/log";
import axios from "axios";
import Coupons from "./Coupons";

export default (function() {
  function categoryShopFilter() {
    const cardCouponsList = document.querySelector('[data-coupons-list]');
    const filter = document.querySelector('[data-widget = "shopFilter"]');
    const loader = document.querySelector('[data-loader]');

    if (cardCouponsList && filter) {
      filter.addEventListener('click', function(ev) {

        if (ev.target.getAttribute('data-shop-id')) {
          let shopIds = [];
          history.replaceState(null, null, window.location.href.replace(/page=[0-9]&?/, ''));

          let checkedBoxes = filter.querySelectorAll('input[type=checkbox]:checked');

          for (let i = 0; i < checkedBoxes.length; i++) {
            shopIds.push(checkedBoxes[i].getAttribute('data-shop-id'));
          }

          let coupons = cardCouponsList.querySelectorAll('[data-coupon-item]');

          for (let i = 0, length = coupons.length; i < length; i++) {
            cardCouponsList.removeChild(coupons[i]);
          }

          loader.classList.remove("hidden");

          let params = {shop_id: shopIds};
          //TODO: can we use some API instead of parsing an entire page?
          axios
            .get(window.location.origin + window.location.pathname, {
              params: params
            })
            .then(function(response) {
              let parser = new DOMParser();
              let doc = parser.parseFromString(response.data, "text/html");
              let cardContent = cardCouponsList;
              let newCardContent = doc.querySelector('[data-coupons-list]');

              if (newCardContent) {
                cardContent.innerHTML = newCardContent.innerHTML;
              }
              loader.classList.add("hidden");
              document.dispatchEvent(new CustomEvent("lazy_images:update"));
              Coupons.run();
            })
            .catch(function(error) {
              log(error);
            });
        }
      });
    }
  }

  function run() {
    log("Categories filter run");
    categoryShopFilter();
  }

  return {
    run: run
  };
})();
