import log from "../../Utils/log";
import mediator from "libs/mediator";

export default (function() {
  const OFFSET = 50;
  let items, ticking;

  function run() {
    log("Lazy Backgrounds Init");

    items = document.querySelectorAll("[data-style]");

    setBackgrounds(items);
    addEvents();
  }

  function isVisible(item) {
    return item.getBoundingClientRect().top - window.innerHeight - OFFSET < 0;
  }

  function setBackgrounds(items) {
    ticking = false;

    items.forEach(item => {
      if (item.dataset.style && isVisible(item)) {
        item.setAttribute("style", item.dataset.style + ";" + (item.getAttribute("style") ? item.getAttribute("style") : ""));
        item.removeAttribute("data-style");
      }
    });
  }

  function requestTick() {
    if (!ticking) {
      window.requestAnimationFrame(() => setBackgrounds(items));
    }
    ticking = true;
  }

  function addEvents() {
    document.addEventListener('scroll', requestTick, false);

    mediator.on("lazy_backgrounds:update", setBackgrounds(items));
  }

  return {
    run: run
  }
})();
