import mediator from "libs/mediator";
import Utils from "../../Utils";

export default (function() {
  let modal = document.getElementById("my-modal");
  let modalHidden = "modal--hidden";
  let backdrop = document.querySelector(".backdrop");
  let backdropVisible = "backdrop--visible";
  let body = document.body;
  let modalOpen = "modal-open";
  let closeButton = document.querySelector(".modal__close-btn");

  function show() {
    Utils.log("Show Modal");

    backdrop.classList.add(backdropVisible);
    modal.classList.remove(modalHidden);
    body.classList.add(modalOpen);
    mediator.trigger("lazy_images:update");
    handleCloseBtnPositioning();
  }

  function handleCloseBtnPositioning() {
    if (!modal) {
      return false;
    }

    const disclaimer =  modal.querySelector(".modal__expired-disclaimer, .modal-clickout__expired-disclaimer");
    const closeBtn = modal.querySelector(".modal__close, .modal__close-btn");

    if (disclaimer) {
      closeBtn.style.top = `${disclaimer.offsetHeight + 5}px`;
    } else if (window.modalCloseBtnTop) {
      closeBtn.style.top = `${window.modalCloseBtnTop}px`;
    }
  }

  function hide() {
    Utils.log("Hide Modal");

    backdrop.classList.remove(backdropVisible);
    modal.classList.add(modalHidden);
    body.classList.remove(modalOpen);
  }

  function run() {
    Utils.log("Modals module init");
    [closeButton, backdrop].forEach(item => {
      item.addEventListener("click", ()=>{
        mediator.trigger("modal:close");
        document.dispatchEvent(new Event("modal:close"))
      });
    })
    mediator.on("modal:show", show);
    document.addEventListener("modal:show", show);
    mediator.on("modal:close", hide);
    document.addEventListener("modal:close", hide);
  }

  return {
    run: run
  };
})();
