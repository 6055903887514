import log from "libs/Utils/log";
import isInViewPort from "libs/Utils/isInViewPort";

let scrollToTopBtn:HTMLElement|null, footerScrollBtn:HTMLElement|null;
const activeClass = 'scroll-to-top--active';

function toggleScrollToTop() {
  if (window.scrollY < window.innerHeight || footerBtnIsVisible(footerScrollBtn)) {
    scrollToTopBtn?.classList.remove(activeClass);
  } else if (!scrollToTopBtn?.classList.contains(activeClass)) {
    scrollToTopBtn?.classList.add(activeClass);
  }
}

function scrollToTop(ev: Event) {
  ev.preventDefault();

  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

function footerBtnIsVisible(footerButton: Element | null): boolean {
  return footerButton ? isInViewPort(footerButton) : false;
}

function run() {
  const disabledSiteIds = ['17', '18', '43', '49', '58', '64', '100'];
  if (window.eventLayer && disabledSiteIds.includes(window.eventLayer[0]?.site_id)) {
    return;
  }

  log("ScrollToTop Init");
  scrollToTopBtn = document.querySelector(".scroll-to-top");
  footerScrollBtn = document.querySelector('.footer--2019 .footer__to-top');

  if (scrollToTopBtn) {
    document.addEventListener("scroll", toggleScrollToTop);
    scrollToTopBtn.addEventListener('click', scrollToTop);
  }

  footerScrollBtn?.addEventListener('click', scrollToTop);
}

export default {run};
