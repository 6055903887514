import Utils from "../../Utils";
import Cookie from "js-cookie";

export default (function() {
  let hideWithTimeout;

  function run() {
    let notification = document.querySelector(".flyout");

    if (notification) {
      fitTexts();
      show(notification);
    }
  }

  function show(notification) {
    let cookieId = notification.dataset.flyoutId;

    if (!isShown(cookieId)) {
      setCookie(cookieId);

      setTimeout(() => {
        notification.style.display = "";
        notification.classList.add("flyout--visible");
        hide(notification);
        mouseOver(notification, hideWithTimeout);
        handleCloseBtn(notification);
        expand(notification);
      }, 5000);
    }

    return cookieId;
  }

  function toggleVisibility(notification) {
    notification.classList.remove("flyout--visible");
    notification.classList.add("flyout--hide");
  }

  function handleCloseBtn(notification) {
    let closeBtn = notification.querySelector(".flyout__close-btn")

    closeBtn.addEventListener("click", (event) => {
      event.stopPropagation();
      toggleVisibility(notification);
    });
  }

  function hide(notification) {
    hideWithTimeout = setTimeout(() => {
      toggleVisibility(notification);
    }, 15000);
  }

  function expand(notification) {
    notification.addEventListener("click", () => {
      let isHide = notification.classList.contains("flyout--hide");

      if(isHide) {
        notification.classList.remove("flyout--hide");
        notification.classList.add("flyout--visible");
      }
    });
  }

  function setCookie(cookieId) {
    let isHTTPS = (document.location.protocol === 'https:');

    if (Cookie.get("flyouts")) {
      let shownNotificationIDs = JSON.parse(Cookie.get("flyouts"));

      if (shownNotificationIDs.indexOf(cookieId) === -1) {
        shownNotificationIDs.push(cookieId);
        Cookie.set("flyouts", JSON.stringify(shownNotificationIDs), {secure: isHTTPS});
      }
    } else {
      Cookie.set("flyouts", JSON.stringify([cookieId]), {secure: isHTTPS});
    }
  }

  function isShown(cookieId) {
    let isShown = false;

    if (Cookie.get("flyouts")) {
      let shownNotificationIDs = JSON.parse(Cookie.get("flyouts"));
      if (typeof shownNotificationIDs === 'number'){
        shownNotificationIDs = String(shownNotificationIDs);
      }
      isShown = (shownNotificationIDs.indexOf(cookieId) > -1);
    }

    return isShown;
  }

  function mouseOver(notification, timer) {
    notification.addEventListener("mouseenter", function() {
      clearTimeout(timer);
    });

    notification.addEventListener("mouseleave", function() {
      hide(notification);
    });
  }

  function fitTexts() {
    Utils.textfit(document.getElementsByClassName("flyout__type"), {
      alignHoriz: true,
      widthOnly: true,
      maxFontSize: 23
    });

    Utils.textfit(document.getElementsByClassName("flyout__amount"), {
      alignHoriz: true,
      widthOnly: true,
      maxFontSize: 24
    });
  }

  return {
    run: run
  };
})();
