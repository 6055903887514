import mediator from "libs/mediator";
import sendEvents from "./sendEvents";
import TrackingEvent from "./TrackingEvent.interface";

export default (function() {
  function copyButton() {
    mediator.on("modal:show", () => {
      const copyBtn = document.querySelector(".modal-clickout__copy");

      if (copyBtn) {
        const title = document.querySelector("#my-modal .coupon__title") as HTMLElement;
        const label = title.innerText.trim();
        const Event: TrackingEvent = {
          type: 'misc',
          tracking_label: label,
          tracking_data: "copy_btn"
        };

        copyBtn.addEventListener("click", () => {
          sendEvents(Event);
        });
      }
    });
  }

  return {
    run: copyButton
  };
})();
