import log from "../Utils/log";
import mediator from "libs/mediator";

export default (function() {
  let couponsSelector = '.coupons-grid__item';
  let couponTypeFilters = document.querySelector('.coupon-filter');
  let coupons = document.querySelectorAll(couponsSelector);
  let currentFilter = "all";

  function run() {
    log("Campaign Page Filter");
    setCouponsDataVisible();
    initCouponTypeFilters();
  }

  function setCouponsDataVisible() {
    document.querySelectorAll(couponsSelector).forEach((ele) => ele.setAttribute('data-visible', true));
  }

  function initCouponTypeFilters() {
    if (!couponTypeFilters) {
      return;
    }

    ['coupon', 'offer'].forEach(function(type) {
      let count = couponsByType(type, true).length,
        buttonTitle = '';

      if (count > 0) {
        let filterButton = couponTypeFilters.querySelector(`li[data-filter-type=${type}]`);

        if (count === 1) {
          buttonTitle = filterButton.getAttribute('data-filter-item-title');
        } else {
          buttonTitle = filterButton.getAttribute('data-filter-items-title');
        }
        if (buttonTitle.length > 0) {
          filterButton.innerHTML = `<span>${count}</span> ` + buttonTitle;
        } else {
          filterButton.querySelector("span").innerHTML = count;
        }
        
        filterButton.addEventListener("click", () => {
          if (currentFilter !== type) {
            currentFilter = type;
            showByType(type);
          }
        });
      } else {
        couponTypeFilters.querySelector(`li[data-filter-type=${type}]`).style.display = 'none';
      }
    });

    let showAllButton = couponTypeFilters.querySelector('li[data-filter-type=all]');

    showAllButton.addEventListener('click', function() {
      if (currentFilter !== "all") {
        currentFilter = "all";
        showByType("all");
      }
    });

    couponTypeFilters.style.display = 'block';
  }

  function couponsByType(type, visible) {
    let selector = '';

    if (type !== 'all') {
      selector = `[data-coupon-type='${type}']`;
    }

    if (typeof visible != 'undefined') {
      selector = selector + `[data-visible=${visible}]`;
    }

    if (selector) {
      return document.querySelectorAll(couponsSelector + selector);
    }

    return document.querySelectorAll(couponsSelector);
  }

  function showByType(type) {
    let filterButton = couponTypeFilters.querySelectorAll(`li[data-filter-type]`);

    filterButton.forEach(function(el) {
      if (el.getAttribute('data-filter-type') === type) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });

    coupons.forEach(function(el) {
      if (type === "all") {
        el.parentNode.style.display = 'block';
      } else {
        if (el.getAttribute('data-coupon-type') === type) {
          el.parentNode.style.display = 'block';
        } else {
          el.parentNode.style.display = 'none';
        }
      }
    });

    mediator.trigger("lazy_images:update");
  }

  return {
    run: run
  };
})();
