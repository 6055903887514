import Clickout from "libs/Tracking.embed/clickout";
export default (function() {
  function run() {
    Clickout.run();
  }

  return {
    run: run
  }
})();
