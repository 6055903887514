import log from "../Utils/log";
import debounce from 'lodash/debounce';

export default (function() {

  const GAP = 0;
  let slideWidth, autoplay, timeout, updateElementsTimer;
  const slideTime = 5000;

  function run() {
    const widget = document.querySelector('[data-widget="premiumSlider"]');
    if (!widget) {
      return;
    }

    log('PremiumSlider:init');

    const container = widget.querySelector('[data-container]');
    const arrowPrev = widget.querySelector('[data-arrow-prev]');
    const arrowNext = widget.querySelector('[data-arrow-next]');
    const indicators = widget.querySelectorAll('[data-indicator-dot]');
    const activeIndicatorClass = 'premium-slider__indicator-item--active';
    const slides = widget.querySelectorAll('[data-slide-index]');
    const curatorsList = widget.querySelector(".premium-slider__curators");
    const curators = widget.querySelectorAll("[data-curator-id]");

    if (!slides.length) {
      return;
    }

    enableAutoPlay();

    const slideIndex = widget.querySelector('[data-slide-index]');
    const resizeObserver = new ResizeObserver(debounce(() => {
      slideWidth = slideIndex.clientWidth;
    }, 300));
    const slideObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const slideIndex = entry.target.dataset.slideIndex;
          updateElements(slideIndex);
        }
      });
    }, {root: container, threshold: 0.3});
    slides.forEach((slide) => {
      slideObserver.observe(slide);
    });

    resizeObserver.observe(container);

    arrowPrev.addEventListener('click', scrollToPrevSlide);
    arrowNext.addEventListener('click', scrollToNextSlide);
    indicators.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        handlerIndication(index);
      });

      dot.addEventListener("mouseenter", pauseAutoPlay);
    });

    container.addEventListener("touchmove", pauseAutoPlay);
    arrowNext.addEventListener("mouseenter", pauseAutoPlay);
    arrowPrev.addEventListener("mouseenter", pauseAutoPlay);

    function scrollToPrevSlide() {
      let maxScrollLeft = container.scrollWidth - slideWidth;
      let value = container.scrollLeft - slideWidth - GAP;
      value = value >= 0 ? value : maxScrollLeft;
      container.scrollLeft = value;
    }

    function scrollToNextSlide() {
      let maxScrollLeft = container.scrollWidth - slideWidth;
      let value = container.scrollLeft + slideWidth + GAP;
      value = value <= maxScrollLeft ? value : 0;
      container.scrollLeft = value;
    }

    function changeActiveDot(index) {
      widget.querySelector(`.${activeIndicatorClass}`).classList.remove(activeIndicatorClass);
      widget.querySelectorAll('[data-indicator-dot]')[index].classList.add(activeIndicatorClass);
    }

    function showCurator(index) {
      if (!curators || !curatorsList) {
        return false;
      }

      const curatorId = slides[index].dataset.targetCuratorId;
      const curator = widget.querySelector(`[data-curator-id="${curatorId}"]`);

      curators.forEach(el => {
        el.classList.remove(`${el.classList[0]}--active`);
      });

      if (curatorId && curator) {
        curator.classList.add(`${curator.classList[0]}--active`);
        curatorsList.classList.add(`${curatorsList.classList[0]}--active`);
      } else {
        curatorsList.classList.remove(`${curatorsList.classList[0]}--active`);
      }
    }

    function handlerIndication(index) {
      container.scrollLeft = index * (slideWidth + GAP);
      changeActiveDot(index);
      showCurator(index);
    }

    function enableAutoPlay() {
      autoplay = setInterval(() => scrollToNextSlide(), slideTime);
    }

    function pauseAutoPlay() {
      clearInterval(autoplay);
      clearTimeout(timeout);
      timeout = setTimeout(enableAutoPlay, slideTime);
    }

    function updateElements(index) {
      clearTimeout(updateElementsTimer);
      updateElementsTimer = setTimeout(() => {
        changeActiveDot(index);
        showCurator(index);
      }, 200);
    }
  }

  return {
    run: run
  };

})();
