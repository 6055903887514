import log from "libs/Utils/log";
import mediator from "libs/mediator";

export default (function() {
  function readMore() {
    const curatedSection = document.querySelector(".curated__section");
    const showMoreButton = curatedSection?.querySelector(".curated__read-more");
    const title = curatedSection?.querySelector(".curated__title");
    const subtitle = curatedSection?.querySelector(".curated__subtitle");

    if (!title || !showMoreButton || !subtitle) return;
    const isSubtitleHasEnoughSpace = (subtitle.clientWidth < (title.clientWidth - showMoreButton.clientWidth)) && subtitle.childElementCount < 2;

    if (isSubtitleHasEnoughSpace) {
      showMoreButton.classList.remove("show");
    }

    showMoreButton.addEventListener("click", () => {
      showMoreButton.classList.toggle("opened");
      subtitle.classList.toggle("opened");
    });
  }
  function init() {
    log("CuratedCoupons init");
    mediator.on("shops_show:init", readMore);
  }

  return {
    run: init
  };
})();
