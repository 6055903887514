// default values - node_modules/textfit/textFit.js

import Utils from "../Utils";
import mediator from "libs/mediator";

export default (function() {
  function fitTexts() {
    Utils.textfit(document.getElementsByClassName("coupon__type"), {
      alignHoriz: true,
      widthOnly: window.couponTypeIsWidthOnly ??= true,
      maxFontSize: window.couponTypeMaxFontSize ??= 23,
      minFontSize: window.couponTypeMinFontSize ??= 8
    });

    Utils.textfit(document.getElementsByClassName("coupon__amount"), {
      alignHoriz: true,
      widthOnly: window.couponAmountIsWidthOnly ??= true,
      maxFontSize: window.couponAmountMaxFontSize ??= 36,
      minFontSize: window.couponAmountMinFontSize ??= 8
    });

    Utils.textfit(document.getElementsByClassName("coupon__esdo-box-line1"), {
      alignHoriz: true,
      widthOnly: true,
      maxFontSize: 36
    });

    Utils.textfit(document.getElementsByClassName("coupon__esdo-box-nhs"), {
      alignHoriz: true,
      widthOnly: true,
      maxFontSize: 23
    });

    Utils.textfit(document.querySelectorAll(".coupon--large .coupon__amount"), {
      alignHoriz: true,
      widthOnly: window.largeCouponAmountIsWidthOnly ??= true,
      maxFontSize: window.largeCouponAmountMaxFontSize ??= 36,
      minFontSize: window.largeCouponAmountMinFontSize ??= 8,
      detectMultiLine: window.largeCouponAmountDetectMultiLine ??= false
    });

    Utils.textfit(document.querySelectorAll(".coupon--large .coupon__type"), {
      alignHoriz: true,
      widthOnly: window.largeCouponTypeIsWidthOnly ??= true,
      maxFontSize: window.largeCouponTypeMaxFontSize ??= 23,
      minFontSize: window.largeCouponTypeMinFontSize ??= 8,
      detectMultiLine: window.largeCouponTypeDetectMultiLine ??= false
    });

    Utils.textfit(document.querySelectorAll(".coupon--large .clickout.btn, .coupon--large .coupon__action-btn-text"), {
      alignHoriz: true,
      widthOnly: true,
      maxFontSize: window.largeCouponActionBtnMaxFontSize ??= 14,
      detectMultiLine: false
    });

    Utils.textfit(document.querySelectorAll(".coupon:not(.coupon--large) .coupon__action .clickout.btn, .coupon:not(.coupon--large) .coupon__action-btn-text"), {
      alignHoriz: true,
      widthOnly: true,
      maxFontSize: window.couponActionBtnMaxFontSize ??= 14,
      detectMultiLine: false
    });
  }

  function couponCountdown() {
    const validCoupons = document.querySelector("[data-element='CL'], [data-element='EP']");

    if (validCoupons) {
      const expiredLabels = validCoupons.querySelectorAll("[data-module='coupon_countdown']");

      if (!expiredLabels || expiredLabels.length == 0) return;

      expiredLabels.forEach(expiredLabel => {
        let elExpiresText = expiredLabel.querySelector("[data-expires-text]");
        let elExpiresCounts = expiredLabel.querySelector("[data-expires-counts]");


        let expiredDate = expiredLabel.dataset.time;
        let expiredText = expiredLabel.dataset.expiresTomorrow;
        let currentDate, days, seconds;

        expiredDate = Date.parse(expiredDate);
        expiredDate = expiredDate / 1000;
        currentDate = Math.floor((new Date).getTime() / 1000);

        seconds = expiredDate - currentDate;
        days = Math.floor(seconds / (60 * 60 * 24)); // Calculate the number of days

        if (days >= 1) {
          elExpiresText.classList.remove('hidden');
          elExpiresText.insertAdjacentText('beforeEnd', expiredText);
        } else {
          elExpiresCounts.classList.remove('hidden');
          Utils.countdown(expiredLabel, {date: expiredLabel.dataset.time, format: "on"});
        }
      });
    }
  }

  function run() {
    Utils.log("Coupons module");
    mediator.trigger("lazy_images:update");
    document.fonts.ready.then(() => {
      fitTexts();
    });
    couponCountdown();
    mediator.trigger("showmore");
  }

  return {
    run: run
  };
})();
