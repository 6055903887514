export default (function() {
  function run() {
    const welcomeWidgets = document.querySelectorAll('[data-widget="welcome-text"]');

    welcomeWidgets.forEach(widget => {
      const welcomeText = widget.querySelector('[data-text]');
      const toggles = widget.querySelectorAll('[data-toggle]');

      if (welcomeText) {
        const modifier = welcomeText.classList[0] + '--full';

        toggles.forEach(toggle => {
          if (toggle instanceof HTMLElement && toggle.dataset.moreText && toggle.dataset.lessText) {
            const moreText = toggle.dataset.moreText;
            const lessText = toggle.dataset.lessText;

            toggle.addEventListener('click', (ev) => {
              ev.preventDefault();
              if (welcomeText.classList.contains(modifier)) {
                welcomeText.classList.remove(modifier);
                toggle.innerText = moreText;
              } else {
                welcomeText.classList.add(modifier);
                toggle.innerText = lessText;
              }
            });
          }
        });
      }
    });
  }

  return {
    run: run
  };
})();
