import mediator from "libs/mediator";
import axios from "axios";

export default (function() {
  function run() {
    const newsletterButton = document.querySelector("[data-widget='topbar-newsletter-btn']") as HTMLElement;

    if (!newsletterButton) return;

    newsletterButton.addEventListener('click', getNewsletterForm);
  }

  function showModal(data: string) {
    const modal = document.querySelector("#my-modal .modal__content") as HTMLElement;
    modal.innerHTML = data;
    mediator.trigger("modal:show");
    mediator.trigger("newsletter-modal:show");
  }

  function getNewsletterForm() {
    const rootDir = window.rootDir;

    axios
      .get(rootDir + '/modals/newsletter_subscribe')
      .then(response => {
        showModal(response.data);
        const showMoreNewsletter = document.querySelector('[data-module="show-more-popup-newsletter"]') as HTMLElement;

        showMoreNewsletter.addEventListener('click', toggleShowMore)
      });
  }

  function toggleShowMore(ev: Event) {
    const button = ev.target as HTMLElement;

    if(!button.hasAttribute('data-see-details')) return;

    const privacyNote = button.closest('[data-privacy-note]')?.querySelector('[data-description]');
    const privacyNoteModifier = privacyNote?.classList[0] + '--small';
    const buttonModifier = button.classList[0] + '--opened';
    const moreText = button.dataset.moreText || 'More';
    const lessText = button.dataset.lessText || 'Less';

    if (privacyNote) {
      if (privacyNote.classList.contains(privacyNoteModifier)) {
        privacyNote.classList.remove(privacyNoteModifier);
        button.classList.add(buttonModifier);
        button.innerText = lessText;
      } else {
        privacyNote.classList.add(privacyNoteModifier);
        button.classList.remove(buttonModifier);
        button.innerText = moreText;
      }
    }
  }

  return {
    run: run
  }
})();
