import mediator from "libs/mediator";
import log from "libs/Utils/log";

export default (function() {
  function scrollToCoupon() {
    const windowHashSplited = window.location.hash?.split("-");
    const couponId = (windowHashSplited[0] == "#id") ? windowHashSplited[1] : "";
    const scrollSetting = document.body.hasAttribute('data-scroll');

    if (!scrollSetting) {
      return;
    }

    const blockClassReserved = "coupon";
    const modifierClass = "--highlighted";
    const clickedCoupon = document.getElementById(`coupon-${couponId}`) || document.querySelector(`[data-coupon-id="${couponId}"]`);
    const scrollOffset = window.scrollYOffset || 0;

    clickedCoupon?.classList.add(`${clickedCoupon?.classList[0] || blockClassReserved}${modifierClass}`);
    clickedCoupon && window.scrollTo({
      top: clickedCoupon.getBoundingClientRect().top - (-window.scrollY) - scrollOffset,
      behavior: "smooth"
    });
  }

  function run() {
    log("ScrollToCoupon init");
    mediator.on("modal:close", scrollToCoupon);
  }

  return {
    run: run
  };
})();
