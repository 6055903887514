import log from "libs/Utils/log";

export default (function() {
  const filter = document.querySelector("[data-module='coupon-filter']") as HTMLElement;
  const extendedFilterModule = document.querySelector("[data-module='extended-filter']") as HTMLElement;
  const couponListWrapper = document.querySelector('[data-coupons-list]') as HTMLElement;

  function hideExtraFilterItems(filterItems: NodeListOf<Element>) {
    filterItems.forEach(item => {
      if (!couponListWrapper.querySelector(`[${item.id}]`)) {
        item.classList.add('hidden');
      }
    })
  }

  function getFilterValue() {
    const checkedInput = extendedFilterModule.querySelector('[data-extended-filter-item] input:checked');

    if (checkedInput) {
      return checkedInput.id;
    }
  }

  function hideOtherCoupons(couponData: string, coupons: NodeListOf<HTMLElement>) {
    const couponsToShow = couponListWrapper.querySelectorAll(`[data-coupon-type-${couponData}]`);
    const newsletter = couponListWrapper.querySelector<HTMLElement>('.coupon--newsletter');
    const earthmarkRating = couponListWrapper.querySelector<HTMLElement>('.coupons__earthmark-rating');

    coupons.forEach((coupon)=> {
      coupon.classList.add('hidden');
      coupon.setAttribute('data-visible', 'false');
    })

    showListOfCoupons(couponsToShow);

    extendedFilterModule.classList.remove('extended-filter--opened');
    setPositionForNotCouponElement(newsletter);
    setPositionForNotCouponElement(earthmarkRating);
    setPositionForFilteredCoupons();
  }

  function showListOfCoupons(coupons: NodeListOf<Element>) {
    coupons.forEach((coupon=> {
      coupon.classList.remove('hidden');
      coupon.setAttribute('data-visible', 'true');
    }))
  }

  function changeCouponsOrder(coupons: NodeListOf<HTMLElement>) {
    coupons.forEach(coupon => {
      if (coupon.dataset.index) {
        coupon.style.order = coupon.dataset.index;
      }
    });
  }

  function setPositionForNotCouponElement(element: HTMLElement | null) {
    if (element instanceof HTMLElement && element.dataset.position) {
      (element as HTMLElement).style.order = element.dataset.position;
    }
  }

  function setPositionForFilteredCoupons() {
    const couponsItemsNotHidden = couponListWrapper.querySelectorAll<HTMLElement>('[data-coupon-item]:not(.hidden)');

    couponsItemsNotHidden.forEach((coupon, index) => {
      coupon.style.order = (index).toString();
    })
  }

  function createHandleClickExtendedFilterButton(coupons: NodeListOf<HTMLElement>) {
    return function() {
      const couponData = getFilterValue();

      if (couponData) {
        couponListWrapper.classList.add(`${couponListWrapper.classList[0]}--filtered`);
        showListOfCoupons(coupons);
        hideOtherCoupons(couponData, coupons);
      }
    }
  }

  function handleClickFilter(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (target.hasAttribute('data-filter-item-extended')) {
      extendedFilterModule.classList.toggle('extended-filter--opened');
    } else if (target.hasAttribute('data-filter-item')) {
      extendedFilterModule.classList.remove('extended-filter--opened');
    }
  }

  function handleClickOutFilter(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const checkedInput = extendedFilterModule.querySelector('[data-extended-filter-item] input:checked');

    if (target.getAttribute('data-filter-type') === 'all' || target.getAttribute('data-filter-type') === 'coupon') {
      if (checkedInput && checkedInput instanceof HTMLInputElement) {
        checkedInput.checked = false;
      }
    } else if (!filter.contains(target)) {
      extendedFilterModule.classList.remove('extended-filter--opened');
    }
  }

  function run() {
    log('ExtendedCouponFilter:init');

    if (!extendedFilterModule) {
      return;
    }

    const couponItems = couponListWrapper.querySelectorAll<HTMLElement>('[data-coupon-item]');
    const extendedFilterItemsHidden = extendedFilterModule.querySelectorAll('.extended-filter__item.hidden');
    const extendedFilterButton = extendedFilterModule.querySelector('[data-extended-filter-button]') as HTMLElement;
    const extendedFilterItems = extendedFilterModule.querySelectorAll('[data-extended-filter-item]');
    const filterItemExtended = filter.querySelector('[data-filter-item-extended]') as HTMLElement;

    filter.addEventListener('click', handleClickFilter);
    document.addEventListener('click', handleClickOutFilter);

    hideExtraFilterItems(extendedFilterItems);
    changeCouponsOrder(couponItems);

    if (extendedFilterItems.length === extendedFilterItemsHidden.length) {
      filterItemExtended.classList.add('hidden');
      extendedFilterModule.classList.add('hidden');

      return;
    }

    extendedFilterButton.addEventListener('click', createHandleClickExtendedFilterButton(couponItems));
  }

  return {
    run: run
  };
})();
